import React, { useState } from "react";
import { Box, HStack } from "@chakra-ui/react";

import { useIsGroupMember } from "_react/app/AppContents";
import { POSITION_GROUP_POSITION_PLAYER } from "_react/shared/_constants/position_groups";
import { getPositionGroupFromPosition } from "_react/shared/_helpers/position_groups";
import {
	AMA_STATS_TABLES_EARLY_ALPHA_IAM_GROUPS,
	AMA_STATS_TABLES_ALPHA_IAM_GROUPS
} from "_react/shared/data_models/iam_new/_constants";
import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import { CollegeSplits } from "_react/playerpage/stat/collegesplits";

import {
	ContentPaddingTopBreakpointValue,
	ToggleTopBreakpointValue,
	TogglePositionBreakpointValue,
	ToggleJustifyContentBreakpointValue,
	ToggleGapBreakpointValue,
	TOGGLE_STYLE
} from "_react/playerpage/_styles";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { BaseballBatIconStyle, BaseballIconStyle } from "_react/playerpage/ama/shared/_styles";
import PositionPlayerStatisticsTables from "_react/playerpage/ama/content/statistics/PositionPlayerStatisticsTables";
import PitcherStatisticsTables from "_react/playerpage/ama/content/statistics/PitcherStatisticsTables";

type TStatisticsTabProps = {
	player: TPlayerPageCombinedPlayer;
	isTwoWayPlayer: boolean;
};

const StatisticsTab = ({ player, isTwoWayPlayer }: TStatisticsTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	const hasAmaStatEarlyAlphaPermissions = useIsGroupMember(AMA_STATS_TABLES_EARLY_ALPHA_IAM_GROUPS);
	const hasAmaStatAlphaPermissions = useIsGroupMember(AMA_STATS_TABLES_ALPHA_IAM_GROUPS);

	const positionGroup = getPositionGroupFromPosition(player?.position, false);
	const [playerType, setPlayerType] = useState(
		positionGroup !== POSITION_GROUP_POSITION_PLAYER ? PLAYER_TYPE_PITCHER : PLAYER_TYPE_BATTER
	);
	const setPlayerTypeToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPlayerType(playerType);
	};

	return (
		<Box position="relative" top={"-8"}>
			{hasAmaStatEarlyAlphaPermissions && (
				<HStack
					top={ToggleTopBreakpointValue}
					position={TogglePositionBreakpointValue}
					justifyContent={ToggleJustifyContentBreakpointValue}
					gap={ToggleGapBreakpointValue}
					zIndex={1}
				>
					{isTwoWayPlayer && (
						<ToggleButton
							toggleOptions={[
								{
									value: PLAYER_TYPE_BATTER,
									label: "Position Player",
									icon: <BaseballBat sx={BaseballBatIconStyle} />
								},
								{
									value: PLAYER_TYPE_PITCHER,
									label: "Pitcher",
									icon: <Baseball sx={BaseballIconStyle} />
								}
							]}
							value={playerType}
							onSelect={setPlayerTypeToggle}
							style={TOGGLE_STYLE}
						/>
					)}
				</HStack>
			)}
			<Box paddingTop={ContentPaddingTopBreakpointValue}>
				{(hasAmaStatEarlyAlphaPermissions || hasAmaStatAlphaPermissions) &&
					playerType === PLAYER_TYPE_BATTER && <PositionPlayerStatisticsTables playerId={player.id} />}
				{(hasAmaStatEarlyAlphaPermissions || hasAmaStatAlphaPermissions) &&
					playerType === PLAYER_TYPE_PITCHER && (
						<PitcherStatisticsTables
							playerId={player.id}
							hasAmaStatEarlyAlphaPermissions={hasAmaStatEarlyAlphaPermissions}
						/>
					)}
				{!(hasAmaStatAlphaPermissions || hasAmaStatEarlyAlphaPermissions) && (
					<CollegeSplits player={player} colorScheme={chakraColorSchemeGroup} />
				)}
			</Box>
		</Box>
	);
};

export default StatisticsTab;
