import React from "react";

import { BATS_L, BATS_OVERALL, BATS_R } from "_react/shared/data_models/baseline_hit_probs/_constants";
import { getAmaLevelDisplayText, getAmaTeamDisplay } from "_react/shared/_helpers/stats";
import { TMetricReliabilityThresholds } from "_react/shared/data_models/metric_reliability/_helpers";
import { TColumn, TParentColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { formatPercentage } from "_react/shared/ui/presentation/components/stat/shared/_helpers";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import SampleSizeField from "_react/shared/ui/presentation/components/Table/SampleSizeField";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import { getMeetsReliabilityThreshold } from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_helpers";
import { ICombinedPitcherPitchOutcomesData } from "_react/shared/ui/data/tables/AmaPitcherPitchOutcomesTable/_types";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: ICombinedPitcherPitchOutcomesData) => row.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICombinedPitcherPitchOutcomesData) => getAmaTeamDisplay(row.team),
	getSortValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
		`${row.team?.name ?? row.team?.trackmanTeam?.level}-${row.teamId}`
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICombinedPitcherPitchOutcomesData) => (
		<TeamLevelBadge
			level={row.team?.level}
			displayName={getAmaLevelDisplayText(row.team?.level)}
			nullFillerText={NULL_FILLER_TEXT}
		/>
	),
	getSortValueFunction: (row: ICombinedPitcherPitchOutcomesData) => {
		return row.team?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
	}
};

const getBatsColumn = (batsFilter: string) => {
	return {
		id: "bats",
		value: "bats",
		label: "Bats",
		isMobile: true,
		valueType: VALUE_TYPE_STRING as TValueType,
		getValueFunction: () => batsFilter
	};
};

const PITCH_TYPE_COLUMN = {
	id: "pitchType",
	value: "pitchType",
	label: "Type",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
		row.lkPitchType?.label && row.lkPitchType?.abbreviation ? (
			<PitchTypeLabel
				label={row.lkPitchType.label}
				abbreviation={row.lkPitchType.abbreviation}
				shape={ICON_CIRCLE}
			/>
		) : (
			"Overall"
		),
	getSortValueFunction: (row: ICombinedPitcherPitchOutcomesData) => row.lkPitchType?.sortOrder ?? 0
};

export const getPitchesColumn = (
	batsFilter: string,
	thresholds?: { [index: string]: TMetricReliabilityThresholds }
) => {
	return {
		id: "pitches",
		value: "pitches",
		label: "Total",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) => {
			const thresholdInfo = getMeetsReliabilityThreshold(row, thresholds);
			const value =
				batsFilter === BATS_L
					? row.totalVl && row.totalVl !== 0
						? row.totalVl
						: NULL_FILLER_TEXT
					: batsFilter === BATS_R
					? row.totalVr && row.totalVr !== 0
						? row.totalVr
						: NULL_FILLER_TEXT
					: batsFilter === BATS_OVERALL
					? row.total && row.total !== 0
						? row.total
						: NULL_FILLER_TEXT
					: NULL_FILLER_TEXT;
			return (
				<SampleSizeField
					sampleSize={value}
					tooltipText={thresholdInfo.tooltip}
					isDisplayWarningSymbol={!thresholdInfo.meetsAllThresholds}
				/>
			);
		}
	};
};

export const getSwingPctColumn = (batsFilter: string) => {
	return {
		id: "swingPct",
		value: "swingPct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.swingPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.swingPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.swingPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXSwingPctColumn = (batsFilter: string) => {
	return {
		id: "swingPctPIntrinsic",
		value: "swingPctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.swingPctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.swingPctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.swingPctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getWhiffPctColumn = (batsFilter: string) => {
	return {
		id: "whiffPct",
		value: "whiffPct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.whiffPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.whiffPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.whiffPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXWhiffPctColumn = (batsFilter: string) => {
	return {
		id: "whiffPctPIntrinsic",
		value: "whiffPctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.whiffPctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.whiffPctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.whiffPctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getChasePctColumn = (batsFilter: string) => {
	return {
		id: "chasePct",
		value: "chasePct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.chasePctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.chasePctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.chasePct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXChasePctColumn = (batsFilter: string) => {
	return {
		id: "chasePctPIntrinsic",
		value: "chasePctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.chasePctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.chasePctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.chasePctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getCswPctColumn = (batsFilter: string) => {
	return {
		id: "cswPct",
		value: "cswPct",
		label: "Act",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.cswPctVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.cswPctVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.cswPct) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getXCswPctColumn = (batsFilter: string) => {
	return {
		id: "cswPctPIntrinsic",
		value: "cswPctPIntrinsic",
		label: "Exp",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? formatPercentage(row.cswPctPIntrinsicVl) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? formatPercentage(row.cswPctPIntrinsicVr) ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? formatPercentage(row.cswPctPIntrinsic) ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getBipsColumn = (batsFilter: string) => {
	return {
		id: "bips",
		value: "bips",
		label: "BIP",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? row.bipsVl ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? row.bipsVr ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? row.bips ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getEvColumn = (batsFilter: string) => {
	return {
		id: "ev",
		value: "ev",
		label: "EV",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? row.exitVeloVl ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? row.exitVeloVr ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? row.exitVelo ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getLaColumn = (batsFilter: string) => {
	return {
		id: "la",
		value: "la",
		label: "LA",
		isMobile: true,
		valueType: VALUE_TYPE_NUMBER as TValueType,
		getValueFunction: (row: ICombinedPitcherPitchOutcomesData) =>
			batsFilter === BATS_L
				? row.launchAngleVl ?? NULL_FILLER_TEXT
				: batsFilter === BATS_R
				? row.launchAngleVr ?? NULL_FILLER_TEXT
				: batsFilter === BATS_OVERALL
				? row.launchAngle ?? NULL_FILLER_TEXT
				: NULL_FILLER_TEXT
	};
};

export const getPitcherPitchOutcomesColumns = (
	batsFilter: string,
	thresholds?: { [index: string]: TMetricReliabilityThresholds },
	hasAmaStatEarlyAlphaPermissions?: boolean
): Array<TColumn<ICombinedPitcherPitchOutcomesData, keyof ICombinedPitcherPitchOutcomesData>> => {
	const columns: Array<TColumn<ICombinedPitcherPitchOutcomesData, keyof ICombinedPitcherPitchOutcomesData>> = [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN
	];
	if (batsFilter !== BATS_OVERALL) {
		columns.push(getBatsColumn(batsFilter));
	}
	columns.push(
		PITCH_TYPE_COLUMN,
		getPitchesColumn(batsFilter, thresholds),
		getSwingPctColumn(batsFilter),
		getXSwingPctColumn(batsFilter),
		getWhiffPctColumn(batsFilter),
		getXWhiffPctColumn(batsFilter),
		getChasePctColumn(batsFilter),
		getXChasePctColumn(batsFilter),
		getCswPctColumn(batsFilter),
		getXCswPctColumn(batsFilter)
	);
	if (hasAmaStatEarlyAlphaPermissions) {
		columns.push(getBipsColumn(batsFilter), getEvColumn(batsFilter), getLaColumn(batsFilter));
	}
	return columns;
};

export const PITCHER_PITCH_OUTCOMES_PARENT_COLUMNS: Array<TParentColumn> = [
	{
		label: "",
		id: "general",
		childColumnIds: ["season", "team", "level", "bats"]
	},
	{ label: "Pitches", id: "pitches", childColumnIds: ["pitchType", "pitches"] },
	{ label: "Swing%", id: "swingPct", childColumnIds: ["swingPct", "swingPctPIntrinsic"] },
	{ label: "Chase%", id: "chasePct", childColumnIds: ["chasePct", "chasePctPIntrinsic"] },
	{ label: "SwStr%", id: "whiffPct", childColumnIds: ["whiffPct", "whiffPctPIntrinsic"] },
	{ label: "CSW%", id: "cswPct", childColumnIds: ["cswPct", "cswPctPIntrinsic"] },
	{
		label: "Exit Data",
		id: "battedBallChars",
		childColumnIds: ["bips", "ev", "la"]
	}
];
